import router from "@/router/index";
import { clientWithToken, updateToken } from "../../helpers/axiosHelper";
import { isMobile } from "../../helpers/generalHelper";
import setToken from "../../messaging_get_token";


const routeHandlerAfterAuthSuccess = () => {
    console.log(router.currentRoute.name)
    switch (router.currentRoute.name) {
      case 'homedashboard':
        router.go(0) // reload
        console.log('home')
        break
  
      case 'ilanlar.aktif':
        // router.go(0) // reload
        console.log(router.currentRoute)
        console.log('ilanlar')
        break
  
      default:
        router.push({ name: 'homedashboard' }) // redirect home
        console.log('default redirect')
        break
    }
    return
  }

  // start broadcast channel
const roleSwitchChannel = new BroadcastChannel("roleSwitch");

// listen channel messages
roleSwitchChannel.onmessage = (event) => {
  window.location.reload()
};

export default{
    async getDealer(context, payload) {
        console.log("GetDealer isteği gönderiliyor");
        await clientWithToken
          .post("/Dealer/GetDealerInfo")
          .then((res) => {
            if (res.data.HasError) {
                console.log("GetDealerInfo isteği hata döndürdü!" + res.data.Message);
              window.sendNotification.error(res.data.Message);
            } else {
                console.log("GetDealerInfo isteği başarıyla cevaplandı! Data > " + res.data)
              /* var dealerInfo = {
                ...res.data.Data,
              }; */
              //user.Role === 5 ? (user.IsCarrier = true) : user.Role === 6 ? (user.IsCarrier = false) : null;
              //console.log("DealerInfo :" +dealerInfo);
              context.commit("setDealerData", res.data.Data);
    
            }
          })
          .catch((err) => {});
      },

      async inviteDealer(context, payload = {}) {
        await clientWithToken.post("Dealer/InviteDealer", payload).then((res) => {
          if (res.data.HasError) {
            window.sendNotification.error(res.data.Message);
          } else {
            window.sendNotification.success(res.data.Message);
          }
          context.commit("inviteDealerResponse", res.data);
        });
      },
}
export default [
    {
        Name: "LookAtNewAdvert", Title: "İlana göz atın", Body: "İlanınıza göz atın. Görüntülemek için tıklayın.", Redirect: "ilanlar/detay?adId="
    },
    {
        Name: "NewBidForAdvert", Title: "Yeni teklif alındı", Body: "Yeni bir teklif var.", Redirect: ""
    },
    {
        Name: "CounterBidForAdvert", Title: "Karşı teklif alındı", Body: "Yeni bir karşı teklif var.", Redirect: ""
    },
    {
        Name: "YourBidHasConfirmed", Title: "Teklifiniz onaylandı", Body: "Verdiğiniz teklif onaylandı.", Redirect: ""
    },
    {
        Name: "ShippingDelivered", Title: "Sevkiyat teslim edildi", Body: "Sevkiyatınız teslim edildi.", Redirect: ""
    },
    {
        Name: "ShippingCompleted", Title: "Sevkiyat tamamlandı", Body: "Sevkiyatınız tamamlandı.", Redirect: ""
    },
    {
        Name: "SupplierAccountConfirmed", Title: "Hesabınız onaylandı", Body: "Hesabınız onaylandı.", Redirect: ""
    },
    {
        Name: "AdvertTimeHasExpired", Title: "İlan süresi sona erdi", Body: "İlan süresi sona erdi. İlanınz kaldırıldı.", Redirect: ""
    },
    {
        Name: "BidRejected", Title: "Teklifiniz reddedildi", Body: "Teklifiniz reddedildi", Redirect: ""
    },
    {
        Name: "GiftNaku", Title: "Hediye Naku", Body: "Hediye nakunuz hesabınıza tanımlandı", Redirect: ""
    },
    {
        Name: "BidsDeleted", Title: "Teklifiniz reddedildi", Body: "İlan yayından kaldırıldığı için teklifiniz reddedildi.", Redirect: ""
    },
    {
        Name: "CancellationRequest", Title: "İptal Talebi", Body: "İptal Talebiniz Kabul Edildi.", Redirect: ""
    },
    {
        Name: "DeclineCancellation", Title: "İptal Talebiniz Reddedildi", Body: "İptal Talebiniz Reddedildi.", Redirect: ""
    },
    {
        Name: "Campains", Title: "Kampanyalar", Body: "", Redirect: ""
    },
]
import router from "@/router/index"
import { clientWithToken, updateToken } from "../../helpers/axiosHelper";
import axios from "axios";
import store from "@/store/index"

export default {
  async confirmBidForSupplier(context, payload) {
    return await clientWithToken
      .post("/Bid/ConfirmBidForSupplier", payload.bidId)
      .then((res) => {
        if (res.data.HasError) {
          window.sendNotification.error(res.data.Message);
        } else {
          if (payload.paymentType == 0) {
            // eslint-disable-next-line no-debugger
          } else {
            window.sendNotification.success(res.data.Message);
          }
        }
        return res.data
      });
  },

  async rejectBidForSupplier(context, payload) {
    return await clientWithToken
      .post("/Bid/RejectBidForSupplier", payload.bidId)
      .then((res) => {
        if (res.data.HasError) {
          window.sendNotification.error(res.data.Message);
        } else {
          window.sendNotification.success(res.data.Message);
          context.dispatch("listAdvertBidsForSupplier", payload.AdvertId)
        }
        return res.data
      });
  },

  async sendCounterBid(context, payload) {
    await clientWithToken
      .post("Bid/SendCounterBid", payload)
      .then((res) => {
        if (res.data.HasError) {
          window.sendNotification.error(res.data.Message);
        } else {
          window.sendNotification.success(res.data.Message);
          context.dispatch("getAdvertById", {
            adId: payload.adId,
          })
        }
      });
  },
};

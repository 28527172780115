import Vue from 'vue'
import {
    clientWithToken
} from './axiosHelper'
import store from "@/store/index"

export const phoneNumberFormatString = (phoneNumber) => {
    const validPhone = phoneNumber?.replaceAll(/\s/g, "").replaceAll("(", "").replaceAll(")", "")
    return validPhone?.length !== 13 ? null : validPhone
}

export const getDistricts = async (CityId) => {
    var districts = null
    await clientWithToken.post("/Definition/GetDistricts", {
        Skip: 0,
        Take: 100,
        CityId,
        SearchTerm: null
    }).then(res => {
        if (!res.data.HasError) {
            districts = res.data.Data.Items.sort((a, b) => {
                var textA = a.Title.toUpperCase();
                var textB = b.Title.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            })
        }
    })

    return districts
}
let timeOut = null;

/**
 *  Dispatch islemlerinin belirli zaman araliklarinda yapilmasi icin kullanilacaktir(cok sik gonderilen istekleri timeout
 *  ile onlemek icin. bkz -> 429:Too Many Request).
 * @param {string} methodName 
 * @param {*} data 
 */
export const lazyDispatch = async (methodName, data) => {
    clearTimeout(timeOut);
    timeOut = setTimeout(async () => {
        await store.dispatch(methodName, data)
    }, 400);
}

/**
 * Objeleri deep copy etmek icin.
 * @param {*} object 
 * @returns copied object
 */
export const deepCopyObject = (object) => JSON.parse(JSON.stringify(object));

/**
 * 
 * @param {string} title 
 * @param {string} body 
 * @param {string} redirect 
 */
export const createNotification = (title, body, redirect = "") => {
    const notification = new Notification(title, {
        body: body
    });
    notification.onclick = (e) => {
        window.location.href = location.origin + "/" + redirect;
    };
}

/**
 * 
 * @param {Date} d1 
 * @param {Date} d2 
 * @returns 
 */
export const numOfDaysBetween = (d1, d2) => {
    var diff = Math.abs(d1.getTime() - d2.getTime());
    return diff / (1000 * 60 * 60 * 24);
}

/**
 * 
 * @returns boolean
 */
export const isMobile = () => /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent.toLowerCase())

Vue.prototype.$phoneNumberFormatString = phoneNumberFormatString
Vue.prototype.$getDistricts = getDistricts
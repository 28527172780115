import { clientWithToken, updateToken } from "../../helpers/axiosHelper";

export default {
    async getNakuPackets(context) {
        await clientWithToken.post("/Store/List", {}).then(res => {
            if (!res.data.HasError) {
                context.commit("nakuCommit", res.data.Data.Items)
            }
        })
    },
    async nakuUsages(context, payload) {
        await clientWithToken.post("/Store/ListNakuUsages", {}).then((res) => {
            context.commit("nakuHistory", res.data.Data.Items)
        });
    },
    async buyNaku(context, payload) {
        return await clientWithToken
            .post("/Store/Buy", payload)
            .then(async (res) => {
                if (res.data.HasError) {
                    window.sendNotification.error(res.data.Message);
                } else {
                    window.sendNotification.success("3D Secure Sayfasına Yönlendiriliyorsunuz...");
                }
                return res.data
            });
    },
}

export default {
    userCommit(state, data) {
        state.user = data
    },
    getUserLoading(state, data) {
        state.loading = data
    },
    removeUser(state, data) {
        state.user = {
            IsCarrier: state.user.IsCarrier
        }
        state.token = null
    },
    setIsCarrier(state,data){
        state.user.IsCarrier = data;
    },
    toggleLoginDialog(state, data) {
        state.toggleLoginDialog = data
    },
    setLoginAsSupplier(state, data) {
        state.loginAsSupplier = data
    },
    setRegisterHomePageDialog(state, data) {
        state.registerHomePageDialog = data
    },
    activeRegister(state, data) {
        state.activeRegister = data
    },
    companyResponse(state, data) {
        state.companyResponse = data
    },
    accTypeChange(state, data) {
        state.user.IsCarrier = !state.user.IsCarrier
    },
    setToken(state, data) {
        state.token = data
    },
    setInvoiceInfo(state, data) {
        state.user.InvoiceInfo = data
    },
    setBankAccount(state, data) {
        state.user.BankAccount = data
    },
    setWarehouses(state, data) {
        state.warehouses = data
    },
    setDeliveryCompanies(state, data) {
        state.deliveryCompanies = data
    },
    setNotificationSettings(state, data) {
        state.user.NotificationSettings = data
    },
    setCarreerVehicles(state, data) {
        state.careerVehicles = data
    },
    vehicleResponse(state, data) {
        state.vehicleResponse = data
    },
    warehouseId(state, data) {
        state.warehouseId = data
    },
    depotResponse(state, data) {
        state.depotResponse = data
    },
    invoiceResponse(state, data) {
        state.invoiceResponse = data
    },
    loginResponse(state, data) {
        state.loginResponse = data
    },
    changePasswordResponse(state, data) {
        state.changePasswordResponse = data
    },
    resetPasswordResponse(state, data) {
        state.resetPasswordResponse = data
    },
    bankResponse(state, data) {
        state.bankResponse = data
    },
    userResponse(state, data) {
        state.userResponse = data
    },
    invoceResponse(state, data) {
        state.invoceResponse = data
    },
    inviteFriendResponse(state, data) {
        state.inviteFriendResponse = data
    },
    forgotPasswordResponse(state, data) {
        state.forgotPasswordResponse = data
    },
    confirmResetKeyResponse(state, data) {
        state.confirmResetKeyResponse = data
    },
    signUpUserInfo(state, data) {
        state.signUpUserInfo = data
    },
    dashboardStatistics(state, data) {
        state.dashboardStatistics = data
    },
    policies(state, data) {
        state.policies = data
    },
    systemSettings(state, data) {
        state.systemSettings = data
    },
}

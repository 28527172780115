export default {
    trailerCommits(state, payload){
        state.truckTrailers = payload
    },
    lorryTrailerCommit(state, payload){
        state.lorryTrailers = payload
    },
    pickupTrailerCommit(state, payload){
        state.pickupTrailers = payload
    }
}

import { clientWithToken } from "../../helpers/axiosHelper";
import router from "@/router/index";

export default {
  async getAdverts(context, payload = {}) {
    var isCarrier = context.getters["userState"].IsCarrier;
    if (!payload) payload = {};
    var res = await clientWithToken.post(isCarrier ? "/Advert/GetAdvertsForCarrier" : "/Advert/GetAdvertsForSupplier", payload);
    if (!res.data.HasError) {
      context.commit("advertsCommit", res.data.Data.Items);
    }
  },

  async getAdvertById(context, payload = {}) {
    await clientWithToken
      .post(
        context.getters["userState"].IsCarrier == true ? "/Advert/GetAdvertsForCarrier" : "/Advert/GetAdvertsForSupplier",
        payload.AdvertId
          ? payload
          : {
              AdvertId: payload.adId,
            },
      )
      .then((res) => {
        if (!res.data.HasError) {
          context.commit("singleAdvertCommit", res.data.Data.Items[0]);
        }
      });
  },

  async deleteAdvertById(context, payload = {}) {
    await clientWithToken.post("/Advert/Delete", payload).then((res) => {
      if (!res.data.HasError) {
        window.sendNotification.success(res.data.Message);
        context.dispatch("getAdverts");
        router.push({
          path: "/ilanlar/aktif",
        });
      }
    });
  },

  async postAdvert(context, payload = {}) {
    return await clientWithToken.post("/Advert/Add", payload).then((res) => {
      if (res.data.HasError) {
        window.sendNotification.error(res.data.Message);
      } else {
        window.sendNotification.success(res.data.Message);
        context.commit("adsDialog", false);
        context.dispatch("getUser");
        context.dispatch("getAdverts");
      }
      return res.data;
    });
  },

  async updateAdvert(context, payload = {}) {
    return await clientWithToken.post("/Advert/Update", payload).then((res) => {
      if (res.data.HasError) {
        window.sendNotification.error(res.data.Message);
      } else {
        window.sendNotification.success(res.data.Message);
        context.commit("adsDialog", false);
        context.dispatch("getAdvertById", {
          adId: payload.ID,
        });
      }
      return res.data;
    });
  },

  async listAdvertBidsForSupplier(context, payload = {}) {
    await clientWithToken.post("/Bid/ListAdvertBidsForSupplier", payload).then((res) => {
      context.commit("bidsInfo", res.data.Data ? res.data.Data.Items : []);
    });
  },

  async confirmBidForCarrier(context, payload = {}) {
    await clientWithToken.post("/Bid/ConfirmBidForCarrier", payload).then((res) => {
      // eslint-disable-next-line no-debugger
      if (res.data.HasError) {
        window.sendNotification.error(res.data.Message);
      } else {
        window.sendNotification.success(res.data.Message);
        router.push({
          name: "Sevkiyatlarım",
        });
      }
    });
  },

  async sendFinalBid(context, payload = {}) {
    await clientWithToken.post("/Bid/SendFinalBid", payload).then((res) => {
      if (res.data.HasError) {
        window.sendNotification.error(res.data.Message);
      } else {
        context.commit("bidModal", false);
        context.dispatch("getAdvertById", {
          adId: payload.AdvertId,
        });
        window.sendNotification.success(res.data.Message);
      }
      context.commit("sendBidResponse", res.data);
      context.dispatch("getAdverts");
    });
  },

  async sendBid(context, payload = {}) {
    await clientWithToken.post("/Bid/SendBid", payload).then((res) => {
      if (res.data.HasError) {
        window.sendNotification.error(res.data.Message);
      } else {
        window.sendNotification.success(res.data.Message);
        context.dispatch("getAdvertById", {
          adId: payload.AdvertId,
        });
        context.commit("bidModal", false);
        context.dispatch("getUser");
        context.dispatch("getAdverts");
      }
      context.commit("sendBidResponse", res.data);
    });
  },

  async getTransferAdverts(context, payload = {}) {
    context.commit("selfLoading", true);
    return await clientWithToken
      .post(context.getters["userState"].IsCarrier == true ? "/Advert/GetTransferAdvertsForCarrier" : "/Advert/GetTransferAdvertsForSupplier", payload)
      .then((res) => {
        if (!res.data.HasError) {
          context.commit("advertInfo", res.data.Data.Items[0]);
        }
        return res.data;
      })
      .finally(() => context.commit("selfLoading", false));
  },
  async getCompletedTransferAdverts(context, payload = {}) {
    context.commit("selfLoading", true);
    return await clientWithToken
      .post(context.getters["userState"].IsCarrier == true ? "/Advert/GetCompletedAdvertsForCarrier" : "/Advert/GetCompletedAdvertsForSupplier", payload)
      .then((res) => {
        if (!res.data.HasError) {
          context.commit("advertInfo", res.data.Data.Items[0]);
        }
        return res.data;
      })
      .finally(() => context.commit("selfLoading", false));
  },

  async getTransferAdvertsShipment(context, payload = {}) {
    await clientWithToken.post(context.getters["userState"].IsCarrier == true ? "/Advert/GetTransferAdvertsForCarrier" : "/Advert/GetTransferAdvertsForSupplier", payload).then((res) => {
      context.commit("shipments", res.data.Data ? res.data.Data.Items : []);
    });
  },

  async getCompletedAdvertsShipment(context, payload = {}) {
    await clientWithToken.post(context.getters["userState"].IsCarrier == true ? "/Advert/GetCompletedAdvertsForCarrier" : "/Advert/GetCompletedAdvertsForSupplier", payload).then((res) => {
      context.commit("completedAdverts", res.data.Data ? res.data.Data.Items : []);
    });
  },
  async getMyRates(context) {
    await clientWithToken.get("/Advert/GetMyRates").then((res) => {
      context.commit("myRates", res.data.Data.Adverts);
    });
  },

  async completeAdvert(context, payload = {}) {
    const user = context.getters["userState"];
    return await clientWithToken.post(user.IsCarrier == false ? "Advert/CompleteAdvertForSupplier" : "/Advert/CompleteAdvertForCarrier", payload).then(async (res) => {
      if (res.data.HasError) {
        window.sendNotification.error(res.data.Message);
      } else {
        window.sendNotification.success(res.data.Message);
        await context.dispatch("getTransferAdvertsShipment", {});
        await context.dispatch("getCompletedAdvertsShipment", {});
        if (user.IsCarrier == false) {
          context.commit("ratingDialog", true);
        }
      }
      return res.data;
    });
  },

  async sendCancellationRequest(context, payload = {}) {
    return await clientWithToken.post("/Advert/SendCancellationRequest", payload).then(async (res) => {
      if (res.data.HasError) {
        window.sendNotification.error(res.data.Message);
      } else {
        window.sendNotification.success(res.data.Message);
        await context.dispatch("getTransferAdvertsShipment", {});
        await context.dispatch("getCompletedAdvertsShipment", {});
      }
      context.commit("cancelResponse", res.data);
      return res.data;
    });
  },

  async commentRate(context, payload = {}) {
    return await clientWithToken.post("/Advert/Rate", payload).then(async (res) => {
      if (res.data.HasError) {
        window.sendNotification.error(res.data.Message);
      } else {
        window.sendNotification.success(res.data.Message);
        await context.dispatch("getTransferAdvertsShipment", {});
        await context.dispatch("getCompletedAdvertsShipment", {});
      }
      return res.data;
    });
  },

  async payNakuForDetails(context, payload = {}) {
    return await clientWithToken.post("/Account/PayNakuForDetails", payload.id).then(async (res) => {
      if (res.data.HasError) {
        window.sendNotification.error(res.data.Message);
      } else {
        context.dispatch("getUser");
        if (context.getters["userState"].IsCarrier) context.dispatch("getAdvertById", payload.advertId);
        else context.dispatch("listAdvertBidsForSupplier", payload.advertId);
        window.sendNotification.success(res.data.Message);
      }
      return res.data;
    });
  },

  async doPaymentAdvert(context, payload = {}) {
    return await clientWithToken.post("/Advert/DoPaymentAdvertQuery", payload).then(async (res) => {
      if (res.data.HasError) {
        window.sendNotification.error(res.data.Message);
      } else {
        window.sendNotification.success("3D Secure Sayfasına Yönlendiriliyorsunuz...");
      }
      return res.data;
    });
  },

  async iyzico3DForm(context, payload = {}) {
    while (!document.forms[0]) {
      await new Promise((r) => setTimeout(r, 500));
    }
    document.forms[0].submit();
  },
};

import {clientWithToken} from "@/helpers/axiosHelper";

export default {
 async getTrailers(context,payload){
    await clientWithToken.post("/Definition/GetTrailers",{Type:0, Skip:0, Take:100, SearchTerm:null}).then(res =>{
        if(!res.data.HasError){
            context.commit("trailerCommits", res.data.Data.Items)
        }
    })
    await clientWithToken.post("/Definition/GetTrailers", {Type:1, Skip:0, Take:100, SearchTerm:null}).then(res => {
        if (!res.data.HasError){
            context.commit("lorryTrailerCommit", res.data.Data.Items)
        }
    })
    await clientWithToken.post("/Definition/GetTrailers", {Type:2, Skip:0, Take:100, SearchTerm:null}).then(res => {
        if (!res.data.HasError){
            context.commit("pickupTrailerCommit", res.data.Data.Items)
        }
    })
 }
}

<template>
  <div id="authentication" class="authincation h-100">
    <div class="container color">
      <div class="row justify-content-center h-100 align-items-center">
        <div class="col-md-6">
          <div class="authentication-content">
            <div class="row no-gutters">
              <div class="col-xl-12">
                <div class="auth-form">
                  <div class="text-center mb-3">
                    <a class="logo" href="https://nakliyekullan.com/"
                      ><img src="../../assets/images/whitelogo.svg" alt=""
                    /></a>
                  </div>
                  <h1 id="displayReviews" class="text-white">
                    {{ text }}
                  </h1>
                  <div>
                    <p class="text-white">
                      Nakliyeci ve Tedarikçiyi bir araya getiren uygulama,
                      nakliyekullan!
                    </p>
                  </div>
                  <div class="text-center mb-4">
                    <button
                      type="submit"
                      class="btn btn-primary btn-block"
                      @click="
                        () => {
                          $router.push('/login');
                        }
                      "
                    >
                      Hesabın Var Mı? Giriş Yap
                    </button>
                  </div>

                  <div class="text-center mb-4">
                    <button
                      type="submit"
                      class="btn signup-button btn-block sign-up"
                      @click="
                        () => {
                          $router.push('/register');
                        }
                      "
                    >
                      Ücretsiz Kayıt Ol
                    </button>
                  </div>

                  <div class="text-center">
                    <button
                      type="submit"
                      class="btn signup-button btn-block sign-up"
                      @click="
                        loginWithoutRegisterDialog = true;
                      "
                    >
                      Kayıt Olmadan Devam Et
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :title="'Kayıt Olmadan Devam Et'" :visible.sync="loginWithoutRegisterDialog" :modal-append-to-body="false" >
      <LoginWithoutRegister @close-dialog="loginWithoutRegisterDialog = false" />
    </el-dialog>

  </div>
</template>

<script>
import META_DATA from "../../constants/metaData";
import LoginWithoutRegister from "../../pages/register/LoginWithoutRegister.vue";


export default {
  components: {
    LoginWithoutRegister,
  },
  data() {
    return {
      interval: null,
      current: true,
      text: "Hızlıca etrafındaki nakliyecileri bul",
      loginWithoutRegisterDialog: false,
    };
  },
  metaInfo() {
    return {
      title: META_DATA.LANDING.Title,
    };
  },
  mounted() {
    const path = require("../../assets/images/bg-1.png");
    this.$("#authentication").css("background-image", `url('${path}')`);
    this.interval = setInterval(() => {
      this.changeImage();
    }, 5000);
  },
  destroyed() {
    clearInterval(this.interval);
  },
  methods: {
    changeImage() {
      const value = this.current ? "0" : "1";
      this.text = this.current
        ? "Yakınındaki tedarikçileri bul"
        : "Hızlıca etrafındaki nakliyecileri bul";
      this.current = !this.current;
      const path = require("../../assets/images/bg-" + value + ".png");
      this.$("#authentication").css("background-image", `url('${path}')`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>

const PROJECT_NAME = "Nakliye Kullan";
export default {
    LANDING: {
        Title: "Hoşgeldiniz |" + " " + PROJECT_NAME
    },
    LOGIN: {
        Title: "Giriş Yap |" + " " + PROJECT_NAME
    },
    REGISTER: {
        Title: "Kayıt Ol |" + " " + PROJECT_NAME
    },
    DASHBOARD: {
        Title: "Kontrol Paneli |" + " " + PROJECT_NAME
    },
    ADS: {
        Title: "İlanlar |" + " " + PROJECT_NAME
    },
    SHIPMENTS: {
        Title: "Sevkiyatlar |" + " " + PROJECT_NAME
    },
    COMMENTS: {
        Title: "Yorumlar |" + " " + PROJECT_NAME
    },
    SHOP: {
        Title: "Mağaza |" + " " + PROJECT_NAME
    },
    PROFILE: {
        Title: "Profil |" + " " + PROJECT_NAME
    },
}
